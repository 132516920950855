<template>
  <div class="help-content d-flex">
    <div class="d-flex flex-grow-1">
      <v-row class="py-13 flex-grow-1" no-gutters>
        <v-col cols="10" lg="8" md="8" sm="10" class="w-90 mx-auto">
          <div class="mb-5">
            <v-btn
              color="primary"
              @click.stop="goTo('/dashboard/help')"
              style="color: #fff !important"
              ><v-icon>mdi-arrow-left</v-icon>Back to help page?</v-btn
            >
          </div>
          <h1 class="text-center mb-5">How to connect with your Messenger?</h1>
          <p class="mb-6 font-weight-medium w-80 text-center mx-auto">
            This document assumes that you already have a verified business
            account, a page and has a facebook developer account. <br />
            Make sure the application is subscribed to the messenger and webhook
            service.
          </p>

          <h2>1. Go to your facebook page.</h2>

          <img :src="mes1" class="w-100 mb-2" />

          <h2>2. Go to the about tab.</h2>

          <img :src="mes2" class="w-100 mb-2" />

          <h2>3. Copy the page id, paste it on Pinoybot and save.</h2>

          <img :src="mes3" class="w-100 mb-2" />

          <h2>4. Switch to your page account. settings.</h2>
          <img :src="mes4" class="w-100 mb-2" />

          <h2>5. Go to the settings tab on the left rail.</h2>
          <img :src="mes5" class="w-100 mb-2" />

          <h2>6. Go to the new pages experience tab on the left rail.</h2>

          <img :src="mes6" class="w-100 mb-2" style="width: 600px" />

          <h2>7. Click the add new button to add an admin.</h2>
          <img :src="mes7" class="w-100 mb-2" />

          <h2>
            8. Paste in the
            <a href="mailto:ai@myndconsulting.com" target="_blank"
              >ai@myndconsulting.com</a
            >
            email from the connect to messenger tab in pinoybot and select the
            Mynd Ai account that has the Pinoybot logo.
          </h2>
          <img :src="mes8" class="w-100 mb-2" />

          <h2>9. Click the give access button.</h2>
          <img :src="mes9" class="w-100 mb-2" />

          <h2>
            10. CONGRATULATIONS! The chatbot will be live within 1 to 2 days..
          </h2>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import messenger1 from "@/assets/help/messenger_1.png";
import messenger2 from "@/assets/help/messenger_2.png";
import messenger3 from "@/assets/help/messenger_3.png";
import messenger4 from "@/assets/help/messenger_4.png";
import messenger5 from "@/assets/help/messenger_5.png";
import messenger6 from "@/assets/help/messenger_6.png";
import messenger7 from "@/assets/help/messenger_7.png";
import messenger8 from "@/assets/help/messenger_8.png";
import messenger9 from "@/assets/help/messenger_9.png";

export default {
  data() {
    return {};
  },

  computed: {
    mes1() {
      return messenger1;
    },
    mes2() {
      return messenger2;
    },
    mes3() {
      return messenger3;
    },
    mes4() {
      return messenger4;
    },
    mes5() {
      return messenger5;
    },
    mes6() {
      return messenger6;
    },
    mes7() {
      return messenger7;
    },
    mes8() {
      return messenger8;
    },
    mes9() {
      return messenger9;
    },
  },

  methods: {
    goTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss">
.help-content {
  h2 {
    margin-top: 10px;
    margin-bottom: 5px !important;
    font-size: 16px;
  }

  ol {
    margin-bottom: 15px !important;
  }

  p {
    font-size: 14px;
  }
}
</style>
